import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import { BrowserRouter, useLocation } from 'react-router-dom';

const scrollStorage = new Map();
let lastKey = null;

const ScrollRestore = () => {
  const location = useLocation();
  useEffect(() => {
    if(lastKey) scrollStorage.set(lastKey, window.scrollY);

    lastKey = location.key;
    const mem = scrollStorage.get(location.key);
    window.scrollTo({ top: mem || 0, behavior: 'smooth' });
  }, [location.key]);
  return null;
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollRestore />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);