export default {
  name: 'AIPerf-LLM ChinaSC2023 List',
  data: [
    {
      manufacturer: 'Alibaba Cloud',
      site: 'Fudan University',
      score: '208300',
      conf: [
        '150 Nodes, Intel(R) Xeon(R) Platinum 8369B* 9600', 
        'NVIDIA A100 80GB SXM * 1200, 150TB Memory,  RoCE100Gb/s',
      ],
    }, {
      manufacturer: 'Casdao',
      site: 'CASDAO SUZHOU',
      score: '138862',
      conf: [
        '32 Nodes, Intel(R) Xeon(R) Platinum 8468*3072',
        'NVIDIA H800 80G SXM * 256,  64TB Memory, IB-1600Gb',
      ],
    }, {
      manufacturer: 'Beijing Beilong Super Cloud Computing Co., Ltd.',
      site: 'BEIJING SUPER CLOUD COMPUTING CENTER',
      score: '122900',
      conf: [
        '207 Nodes,  Intel(R) Xeon(R) Platinum 8358 CPU * 414',
        'NVIDIA A800 80GB SXM* 1656，207TB Memory, 4×200Gb Infiniband 4X HDR',
      ],
    }, {
      manufacturer: 'NVIDIA',
      site: 'Beijing Normal University',
      score: '18842',
      conf: [
        '19 Nodes, AMD EPYC 7742 64-Core Processor * 2560',
        'NVIDIA A800 80GB SXM*152, 38TB Memory, InfiniBand HDR 200Gb/s',
      ],
    }, {
      manufacturer: 'Nettrix Information Industry Co,LTD',
      site: 'WQ & UCAS Research Academy Intelligent Computing Center',
      score: '15425',
      conf: [
        '16 Nodes, Intel Xeon Platinum 8358P, NVIDIA TESLA A800 80GB PCIE*128',
        '32TB Memory, Mobile Internet Dedicated Line 1000M',
      ],
    }, {
      manufacturer: 'NVIDIA',
      site: 'Tsinghua University',
      score: '753',
      conf: [
        '1 Node，Intel(R) Xeon(R) Gold 6240*72',
        'NVIDIA Tesla V100 32GB SXM * 8, 503GB Memory',
      ],
    }, {
      manufacturer: 'INSPUR GROUP CO.,LTD.',
      site: 'Thewake Systems Co. Ltd',
      score: '613',
      conf: [
        '1 Node, AMD EPYC 7453 28-Core x 2',
        'AMD Instinct GPU x 8, 1TB Memory'
      ],
    }, {
      manufacturer: 'SITONHOLY (TIANJIN) Technology Co., Ltd.',
      site: 'Thewake Systems Co. Ltd',
      score: '567',
      conf: [
        '1 Node, AMD EPYC 7513 32-Core x 4',
        'AMD Instinct GPU x 8 , 1TB Memory',
      ],
    }, {
      manufacturer: 'INSPUR',
      site: 'A Scientific Research Institute',
      score: '559',
      conf: [
        '2 Nodes, Intel(R) Xeon(R) Gold 5218* 128, NVIDIA Tesla V100 32GB PCIe * 16',
        '754GB Memory, InfiniBand HDR 200Gb/s'
      ],
    }, {
      manufacturer: 'Tianjin Teer Technology Co.，Ltd',
      site: 'Tianjin Teer Technology Co.，Ltd.',
      score: '330',
      conf: [
        '2 Nodes, Intel Xeon Platinum 8352V*144',
        'NVIDIA GeForce 4090 24GB *16, 2TB Memory, Mellanox cx5 25Gb',
      ],
    }
  ]
}
