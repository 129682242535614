const demoRow = {
  manufacturer: 'Pengcheng Laboratory',
  site: 'Pengcheng Laboratory',
  score: '194527.50',
  conf: [
    'CloudBrainII, 512nodes, Ascend910(32G)*4096',
    'Kunpeng 9210 2.6GHz x2048, ROCE 100G network',
  ],
};

export default {
  name: 'List 2 (demo)',
  data: new Array(10).fill(demoRow),
}